export const ADMIN = 'group:admin';
export const STAFF = 'group:staff';
export const BILLER = 'group:medical_biller';
export const SUPER_ADMIN = 'group:super_admin';
export const ATLAS_ADMIN = 'group:atlas:admin';
export const CDX_REPORTING_ADMIN = 'group:cdx:reporting_admin';
export const BILLING_SPECIALIST = 'group:compass:billing_specialist';
export const SPRING_ENGINEER = 'group:engineer';
export const SALES = 'group:sales';
export const COMPASS = 'compass';

// Make sure this always match compass roles
const COMPASS_ROLES = [
  'group:compass:care_provider',
  'group:compass:associate_provider',
  'group:compass:med_manager',
  'group:compass:coach',
  'group:compass:care_navigator',
  'group:compass:care_support_coordinator',
  'group:compass:admin',
  'group:compass:clinical_manager',
  'group:compass:clinical_support',
  'group:compass:super_admin',
  'group:compass:billing_specialist',
  'group:compass:care_consultant',
  'group:compass:care_advocate',
  'group:compass:group_practice_admin',
  'group:compass:specialty_care_navigator',
  'group:compass:peer_recovery_specialist',
  'group:compass:specialty_care_navigator',
  'group:compass:manager_consultant',
  'group:compass:employee_consultant',
];

export const ALLOWED_SCOPES = [ADMIN, ATLAS_ADMIN, BILLER, SUPER_ADMIN, SALES, CDX_REPORTING_ADMIN, ...COMPASS_ROLES];
